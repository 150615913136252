<template>
	<el-main class="bg">
		<el-form label-position="right" :model="query" class="form p_4" label-width="120">
			<el-row>
				<el-col :xs="24" :sm="24" :lg="8">
					<el-form-item label="通知序号">
						<el-input v-model="query.notification_serial_number"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :lg="8">
					<el-form-item label="日期">
						<el-input v-model="query.date"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :lg="8">
					<el-form-item label="类型">
						<el-select v-model="query.type">
                            <el-option v-for="o in list_type" :key="o" :label="o"
                            	:value="o">
                            </el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :lg="8">
					<el-form-item label="职工编号">
						<el-select v-model="query.employee_number">
							<el-option v-for="o in list_employee_number" :key="o['username']" :label="o['nickname'] + '-' + o['username']"
									   :value="o['user_id']">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="10" :lg="8">
					<el-form-item>
						<el-button type="primary" @click="search()">查询</el-button>
						<el-button @click="reset()" style="margin-right: 74px;">重置</el-button>
						<router-link v-show="user_group == '管理员' || $check_action('/notification_management/table','add') || $check_action('/notification_management/view','add')" class="el-button el-button--default el-button--primary" to="./view?">添加
						</router-link>
						<el-button v-show="user_group == '管理员' || $check_action('/notification_management/table','del') || $check_action('/notification_management/view','del')" class="" type="danger" @click="delInfo()">删除</el-button>
					</el-form-item>
				</el-col>

			</el-row>
		</el-form>
		<el-table :data="list" @selection-change="selectionChange" @sort-change="$sortChange" style="width: 100%">

			<el-table-column fixed type="selection" tooltip-effect="dark" width="55">
			</el-table-column>
			<el-table-column prop="notification_serial_number" label="通知序号"
				v-show="user_group == '管理员' || $check_field('get','notification_serial_number')" min-width="200">
			</el-table-column>
			<el-table-column prop="date" label="日期"
				v-show="user_group == '管理员' || $check_field('get','date')" min-width="200">
                <template slot-scope="scope">
                	{{ $toTime(scope.row["date"],"yyyy-MM-dd") }}
                </template>
			</el-table-column>
			<el-table-column prop="content" label="内容"
				v-show="user_group == '管理员' || $check_field('get','content')" min-width="200">
			</el-table-column>
			<el-table-column prop="type" label="类型"
				v-show="user_group == '管理员' || $check_field('get','type')" min-width="200">
			</el-table-column>
			<el-table-column prop="remarks" label="备注"
				v-show="user_group == '管理员' || $check_field('get','remarks')" min-width="200">
			</el-table-column>
			<el-table-column prop="start_time" label="开始时间"
				v-show="user_group == '管理员' || $check_field('get','start_time')" min-width="200">
                <template slot-scope="scope">
                	{{ $toTime(scope.row["start_time"],"hh:mm:ss") }}
                </template>
			</el-table-column>
			<el-table-column prop="co_timing" label="共计时"
				v-show="user_group == '管理员' || $check_field('get','co_timing')" min-width="200">
			</el-table-column>
			<el-table-column prop="end_time" label="停止时间"
				v-show="user_group == '管理员' || $check_field('get','end_time')" min-width="200">
                <template slot-scope="scope">
                	{{ $toTime(scope.row["end_time"],"hh:mm:ss") }}
                </template>
			</el-table-column>
			<el-table-column prop="employee_number" label="职工编号"
				v-show="user_group == '管理员' || $check_field('get','employee_number')" min-width="200">
				<template slot-scope="scope">
					{{ get_user_employee_number(scope.row['employee_number']) }}
				</template>
			</el-table-column>
			<el-table-column prop="order_number" label="订单编号"
				v-show="user_group == '管理员' || $check_field('get','order_number')" min-width="200">
			</el-table-column>
			<el-table-column prop="trade_name" label="商品名称"
				v-show="user_group == '管理员' || $check_field('get','trade_name')" min-width="200">
			</el-table-column>
			<el-table-column label="审核状态" prop="examine_state" v-show="user_group == '管理员' || $check_field('get','examine_state')">
			</el-table-column>
			<el-table-column label="审核回复" prop="examine_reply" v-show="user_group == '管理员' || $check_field('get','examine_reply')">
			</el-table-column>

            <el-table-column sortable prop="create_time" label="创建时间" min-width="200">
                <template slot-scope="scope">
                	{{ $toTime(scope.row["create_time"],"yyyy-MM-dd hh:mm:ss") }}
                </template>
            </el-table-column>

			<el-table-column sortable prop="update_time" label="更新时间" min-width="200">
                <template slot-scope="scope">
                	{{ $toTime(scope.row["update_time"],"yyyy-MM-dd hh:mm:ss") }}
                </template>
			</el-table-column>

			<el-table-column fixed="right" label="操作" min-width="120" v-if="user_group == '管理员' || $check_action('/notification_management/table','set') || $check_action('/notification_management/view','set') || $check_action('/notification_management/view','get') || $check_action('//table','add') || $check_action('//view','add')" >
				<template slot-scope="scope">
					<router-link class="el-button el-button--small is-plain el-button--success" style="margin: 5px !important;"
					v-if="user_group == '管理员' || $check_action('/notification_management/table','set') || $check_action('/notification_management/view','set') || $check_action('/notification_management/view','get')"
						:to="'./view?' + field + '=' + scope.row[field]"
						 size="small">
						<span>编辑</span>
					</router-link>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页器 -->
		<div class="mt text_center">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="query.page" :page-sizes="[7, 10, 30, 100]" :page-size="query.size"
				layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</div>
		<!-- /分页器 -->
	</el-main>
</template>
<script>
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		data() {
			return {
				// 获取数据地址
				url_get_list: "~/api/notification_management/get_list?like=0",
				url_del: "~/api/notification_management/del?",

				// 字段ID
				field: "notification_management_id",

				// 查询
				query: {
					"size": 7,
					"page": 1,
					"notification_serial_number": "",
					"date": "",
					"type": "",
					"employee_number": "",
					"login_time": "",
					"create_time": "",
				},

				// 数据
				list: [],
				// 类型列表
				list_type: ['轮牌','客点'],
				list_employee_number: [],
				// 用户列表
				list_user_employee_number: [],
			}
		},
		methods: {
			get_list_before(param){
				var user_group = this.user.user_group;
				if(user_group != "管理员"){
					switch(user_group){
						case "技师":
						param["employee_number"] = this.user.user_id;
						break;
					}
				}
				return param;
			},
			/**
			 * 获取职工编号列表
			 */
			async get_list_employee_number() {
				var json = await this.$get("~/api/user/get_list?user_group=技师");
				if(json.result && json.result.list){
					this.list_employee_number = json.result.list;
				}
				else if(json.error){
					console.error(json.error);
				}
			},

			open_tip() {
				const h = this.$createElement;

				var message = "";
				var list = this.list;

				var ifs = [ ];
				for (var n = 0; n < ifs.length; n++) {
					var o = ifs[n];
					for (var i = 0; i < list.length; i++) {
						var lt = list[i];
						if (o.type == "数内") {
							if ((o.start || o.start === 0) && (o.end || o.end === 0)) {
								if (lt[o.factor] > o.start && lt[o.factor] < o.end) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.start || o.start === 0) {
								if (lt[o.factor] > o.start) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.end || o.end === 0) {
								if (lt[o.factor] < o.end) {
									o["idx"] = o["idx"] + 1;
								}
							}
						} else if (o.type == "数外") {
							if ((o.start || o.start === 0) && (o.end || o.end === 0)) {
								if (lt[o.factor] < o.start || lt[o.factor] > o.end) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.start || o.start === 0) {
								if (lt[o.factor] < o.start) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.end || o.end === 0) {
								if (lt[o.factor] > o.end) {
									o["idx"] = o["idx"] + 1;
								}
							}
						} else if (o.type == "日内") {
							if ((o.start) && (o.end)) {
								if (lt[o.factor] > o.start && lt[o.factor] < o.end) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.start) {
								if (lt[o.factor] < o.start) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.end) {
								if (lt[o.factor] > o.end) {
									o["idx"] = o["idx"] + 1;
								}
							}
						} else if (o.type == "日外") {
							if (o.start && o.end) {
								if (lt[o.factor] < o.start || lt[o.factor] > o.end) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.start) {
								if (lt[o.factor] < o.start) {
									o["idx"] = o["idx"] + 1;
								}
							} else if (o.end) {
								if (lt[o.factor] > o.end) {
									o["idx"] = o["idx"] + 1;
								}
							}
						}
					}

					if (o["idx"]) {
						message += o.title;
						if (o["type"] == "数内") {
							if (o.start || o.start === 0) {
								message += "大于" + o.start;
							}
							if ((o.start || o.start === 0) && (o.end || o.end === 0)) {
								message += "并且";
							}
							if (o.end || o.end === 0) {
								message += "小于" + o.end;
							}
						} else if (o["type"] == "数外") {
							if (o.start || o.start === 0) {
								message += "小于" + o.start;
							}
							if ((o.start || o.start === 0) || (o.end || o.end === 0)) {
								message += "或者";
							}
							if (o.end || o.end === 0) {
								message += "大于" + o.end;
							}
						} else if (o["type"] == "日内") {
							if (o.start) {
								message += "在" + o.start + "之后";
							}
							if (o.start && o.end) {
								message += "并且";
							}
							if (o.end) {
								message += "在" + o.end + "之前";
							}
						} else if (o["type"] == "日外") {
							if (o.start) {
								message += "在" + o.start + "之前";
							}
							if (o.start || o.end) {
								message += "或者";
							}
							if (o.end) {
								message += "在" + o.end + "之后";
							}
						}
						message += "的有" + o["idx"] + "条";
					}
				}

				if (message) {
					message += "，需要处理、请尽快处理。";

					this.$notify({
						title: '提醒',
						dangerouslyUseHTMLString: true,
						message: h('i', {
							style: 'color: teal'
						}, message)
					});
				}
			},
			/**
			 * 获取技师用户列表
			 */
			async get_list_user_employee_number() {
				var json = await this.$get("~/api/user/get_list?user_group=技师");
				if(json.result && json.result.list){
					this.list_user_employee_number = json.result.list;
				}
				else if(json.error){
					console.error(json.error);
				}
			},

			get_user_employee_number(id){
				var obj = this.list_user_employee_number.getObj({"user_id":id});
				var ret = "";
				if(obj){
					if(obj.nickname){
						ret = obj.nickname;
					}
					else{
						ret = obj.username;
					}
				}
				return ret;
			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			}

		},
		created() {
			this.get_list_employee_number();
			this.get_list_user_employee_number();
			setTimeout(() => {
				this.open_tip();
			}, 1000)
		}
	}
</script>

<style type="text/css">
	.bg {
		background: white;
	}

	.form.p_4 {
		padding: 1rem;
	}

	.form .el-input {
		width: initial;
	}

	.mt {
		margin-top: 1rem;
	}

	.text_center {
		text-align: center;
	}

	.float-right {
		float: right;
	}
</style>
